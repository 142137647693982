import Image from "next/image"
import Link from "next/link"
import styles from "./SharingData.module.scss"
import {
  PinterestShareButton
} from 'react-share';
import Swal from 'sweetalert2';
import SocialLoader from "../Loader/SocialLoader";
import { useEffect } from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";

const SharingData = ({ mediaUrl, copyUrl, normalUrl, onShareFunction, props, loader }) => {
  useEffect(() => {
    if (mediaUrl) {
      document.getElementById("shareid").click()
    }
  }, [mediaUrl])

  return (
    <>
      <ul className={`${styles.sharedetails_main}`}>
        {!loader ? <li className={`${styles.sharedetails_list}`}>
          {mediaUrl && <PinterestShareButton media={mediaUrl} url={normalUrl} description="" className={`${styles.sharedetails_click}`} id="shareid"></PinterestShareButton>}
          <OverlayTrigger overlay={<Tooltip> Share</Tooltip>}><Image src="/assets/pinterest.svg" alt="" height={20} width={20} onClick={() => onShareFunction(props)} /></OverlayTrigger>
        </li> : <SocialLoader />}
        <li className={`${styles.sharedetails_list}`}>
          <span className={`${styles.sharedetails_click}`}>
            <a
              onClick={async () => {
                if (navigator.clipboard && window.isSecureContext) {
                  // navigator clipboard api method'
                  navigator.clipboard.writeText(copyUrl);
                  Swal.fire({
                    icon: 'success',
                    title:
                      'Link copied to clipboard!',
                    showConfirmButton: false,
                    timer: 2500,
                  });
                } else {
                  // text area method
                  let textArea = document.createElement('textarea');
                  textArea.value = copyUrl;
                  // make the textarea out of viewport
                  textArea.style.position = 'fixed';
                  textArea.style.left = '-999999px';
                  textArea.style.top = '-999999px';
                  document.body.appendChild(textArea);
                  textArea.focus();
                  textArea.select();
                  await new Promise((res, rej) => {
                    // here the magic happens
                    document.execCommand('copy') ? res() : rej();
                    textArea.remove();
                  });
                  Swal.fire({
                    icon: 'success',
                    title:
                      'Link copied to clipboard!',
                    showConfirmButton: false,
                    timer: 2500,
                  });
                }
              }}
              className={`${styles.link_style}`}
            >
              <OverlayTrigger overlay={<Tooltip> Copy</Tooltip>}><Image src="/assets/u_copy.svg" alt="" height={20} width={20} /></OverlayTrigger>
            </a>
          </span>
        </li>
      </ul>
    </>

  )
}

export default SharingData
