import React from 'react'
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton'
import styles from './SocialLoader.module.scss'
const SocialLoader = () => {
  return (
    <ul className={`${styles.sharedetails_main}`}>
      <li className={`${styles.sharedetails_list}`}>
        <SkeletonTheme baseColor="#c0c0c0" highlightColor="#f5f5f5">
          <Skeleton style={{ height: "27px", width: "27px" }} circle={true} />
        </SkeletonTheme>
      </li>
      {/* <li className={`${styles.sharedetails_list}`}>
        <Skeleton style={{ height: "27px", width: "27px" }} circle={true} />
      </li> */}
    </ul>
  )
}

export default SocialLoader